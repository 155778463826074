import * as types from "./actionTypes";

const initialState = {
  loading: false,
  allUserList: [],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  userManagementDetails: "",
  userManagementDetailsLoading: false,
  userManagementDetailsError: "",
};

const SubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.ALL_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        allUserList: action.payload,
      };

    case types.ALL_USER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        allUserList: [],
        isError: true,
      };

    case types.USER_MANAGEMENT_DATAILS_REQUEST:
      return {
        ...state,
        userManagementDetailsLoading: true,
      };

    case types.USER_MANAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        userManagementDetailsLoading: false,
        userManagementDetails: action.payload,
      };

    case types.USER_MANAGEMENT_DETAILS_ERROR:
      return {
        ...state,
        userManagementDetailsLoading: false,
        userManagementDetailsError: action.payload,
      };

    default:
      return state;
  }
};

export default SubjectReducer;
