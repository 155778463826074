import HTTP from "./http";

class APIList {
  constructor() {}
  userAuthLogin(params) {
    return HTTP.POST("api/login", params);
  }
  getProfileData() {
    return HTTP.GET("api/v1/user/profile");
  }
  getCourseList(params) {
    return HTTP.GET("api/v1/courses", params);
  }
  createCourse(params) {
    return HTTP.POST("api/v1/courses", params);
  }
  updateCourse(params, courseId) {
    return HTTP.PUT(`api/v1/courses/${courseId}`, params);
  }
  getCourseSubjectLesson(uuid) {
    return HTTP.GET(`api/v1/all-courses/${uuid}`);
  }
  updateCourseSetting(params, courseId) {
    return HTTP.PUT(`api/v1/courses/${courseId}/settings`, params);
  }
  getSubjectList(params) {
    return HTTP.GET("api/v1/admin/subjects", params);
  }
  createSubject(params) {
    return HTTP.POST("api/v1/admin/subjects", params);
  }
  updateSubject(id, params) {
    return HTTP.PUT(`api/v1/admin/categories/${id}`, params);
  }
  deleteSubject(id) {
    return HTTP.DELETE(`api/v1/admin/categories/${id}`);
  }
  getSubjectLesson(subjectid) {
    return HTTP.GET(`api/v1/admin/subjects/${subjectid}`);
  }
  getLessonList(params, page) {
    return HTTP.GET(`api/v1/lessons?page=${page}`, params);
  }
  createLesson(params) {
    return HTTP.POST("api/v1/lessons", params);
  }
  updateLesson(params, uuid) {
    return HTTP.PUT(`api/v1/lessons/${uuid}`, params);
  }
  updateLessonSetting(params, uuid) {
    return HTTP.PUT(`api/v1/lessons/${uuid}/settings`, params);
  }
  lessonAssignToCourse(uuid, params) {
    return HTTP.PUT(`api/v1/lessons/${uuid}/assign-courses`, params);
  }
  lessonDetails(uuid) {
    return HTTP.GET(`api/v1/lessons/${uuid}`);
  }
  getAllUser() {
    return HTTP.GET("api/v1/user/all-users");
  }
  assignCourseToStudent(params) {
    return HTTP.POST("api/v1/admin/assign-course-to-user", params);
  }
  createLiveClass(params) {
    return HTTP.POST("api/v1/admin/live-classes", params);
  }
  getLiveClass(page) {
    return HTTP.GET(`api/v1/admin/live-classes?page=${page} `);
  }
  updateLiveClass(courseId, params) {
    return HTTP.POST(`api/v1/admin/live-classes/${courseId}`, params);
  }
  deleteLiveClass(id) {
    return HTTP.DELETE(`api/v1/admin/live-classes/${id}`);
  }
  getLiveClassToken(params) {
    return HTTP.POST("api/v1/generate-zoom-signature", params);
  }
  userDetails(uuid) {
    return HTTP.GET(`api/v1/user/${uuid}`);
  }
  editLiveClass(request, id) {
    return HTTP.PUT(`api/v1/admin/live-classes/${id}`, request);
  }
}

export default new APIList();
