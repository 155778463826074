export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_ERROR = "ALL_USER_LIST_ERROR";

export const ASSIGN_COURSE_TO_USER_REQUEST = "ASSIGN_COURSE_TO_USER_REQUEST";
export const ASSIGN_COURSE_TO_USER_SUCCESS = "ASSIGN_COURSE_TO_USER_SUCCESS";
export const ASSIGN_COURSE_TO_USER_ERROR = "ASSIGN_COURSE_TO_USER_ERROR";

export const USER_MANAGEMENT_DATAILS_REQUEST = 'USER_MANAGEMENT_DATAILS_REQUEST';
export const USER_MANAGEMENT_DETAILS_SUCCESS = 'USER_MANAGEMENT_DETAILS_SUCCESS';
export const USER_MANAGEMENT_DETAILS_ERROR = 'USER_MANAGEMENT_DETAILS_ERROR';

