import { apiConfig, HeaderSecretKey, mode } from "../Constants/Config";
// import localStorage from '@react-native-community/async-storage'
import App from "./../navigation/index";

class HTTP {
  constructor() {}

  GET = async (url) => {
    let mainDomain;
    if (mode === "production") {
      mainDomain = process.env.REACT_APP_BASE_API_URL;
    } else if (mode === "testing") {
      mainDomain = process.env.REACT_APP_BASE_API_URL;
    } else if (url === "oauth/token") {
      mainDomain = process.env.REACT_APP_BASE_API_URL;
    } else {
      mainDomain = process.env.REACT_APP_BASE_API_URL;
    }

    let accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      accessToken = "sENrkbXK4lOqtiv"; // Default token, if needed
    }
    try {
      const response = await fetch(mainDomain + url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
      });

      // console.log("response", response.status, response);

      if (response.status === 401) {
        // Remove token and redirect to login if unauthorized
        localStorage.removeItem("accessToken");
        // You can use react-router-dom's useNavigate here to redirect
        // navigate('/sign');
      } else if (response.status === 200) {
        const responseJson = await response.json();

        if (responseJson.status === 401) {
          localStorage.removeItem("accessToken");
          // navigate('/sign');
        }
        if (responseJson) {
          return responseJson;
        } else {
          return { error: "No response data", status: "failed" };
        }
      } else {
        return null; // Handle non-success status
      }
    } catch (error) {
      // console.error("Request error:", error); // Log the error
      return { error: error.message || "Request failed", status: "failed" };
    }
  };

  POST = async (url, data) => {
    let mainDomain = process.env.REACT_APP_BASE_API_URL;

    // Access token from localStorage (no need for async/await in React.js)
    let accessToken = localStorage.getItem("accessToken") || "";

    try {
      // Make the POST request
      let response = await fetch(mainDomain + url, {
        method: "POST",
        credentials: "same-origin", // Optional depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // If this is FormData, Content-Type will be set automatically
      });
      if (response.status === 200 || response.status === 201) {
        const responseJson = await response.json();
        if (responseJson) {
          return responseJson;
        } else {
          return { error: responseJson.error.message, status: "failed" };
        }
      } else {
        return null; // Handle non-success statuses
      }
    } catch (error) {
       // Log error explicitly
       return error;
    }
  };
  PUT = async (url, data) => {
    let mainDomain = process.env.REACT_APP_BASE_API_URL;

    // Access token from localStorage
    let accessToken = localStorage.getItem("accessToken") || "";

    try {
      // Make the PUT request
      let response = await fetch(mainDomain + url, {
        method: "PUT",
        credentials: "same-origin", // Optional depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // If this is FormData, Content-Type will be set automatically
      });
      if (response.status === 200 || response.status === 201) {
        const responseJson = await response.json();
        if (responseJson) {
          return responseJson;
        } else {
          return { error: responseJson.error.message, status: "failed" };
        }
      } else {
        return null; // Handle non-success statuses
      }
    } catch (error) {
      // console.error("Request error:", error); // Log error explicitly
      return error;
    }
  };
  DELETE = async (url, data) => {
    let mainDomain = process.env.REACT_APP_BASE_API_URL;

    // Access token from localStorage
    let accessToken = localStorage.getItem("accessToken") || "";

    try {
      // Make the PUT request
      let response = await fetch(mainDomain + url, {
        method: "DELETE",
        credentials: "same-origin", // Optional depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // If this is FormData, Content-Type will be set automatically
      });
      if (response.status === 200 || response.status === 201) {
        const responseJson = await response.json();
        if (responseJson) {
          return responseJson;
        } else {
          return { error: responseJson.error.message, status: "failed" };
        }
      } else {
        return null; // Handle non-success statuses
      }
    } catch (error) {
      // console.error("Request error:", error); // Log error explicitly
      return error;
    }
  };
}
export default new HTTP();
