import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getLessonListAction = ({ request, page }) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = request?.isRefresh
      ? types.Lesson_LIST_REFRESH_REQUEST
      : types.Lesson_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getLessonList(request, page);
      if (response.status == 200) {
        dispatch({ type: types.Lesson_LIST_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: types.Lesson_LIST_ERROR, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.Lesson_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createLessonAction = (
  request,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_LESSON_REQUEST });

      const response = await ApiList.createLesson(request);
      if (response) {
        dispatch({ type: types.CREATE_LESSON_SUCCESS, payload: response });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
      } else {
        dispatch({ type: types.CREATE_LESSON_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_LESSON_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const updateLessonSettingAction = ({
  request,
  uuid,
  onStepChange,
  setIsStep2Complete,
  isLessonEdit,
}) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_LESSON_SETTING_REQUEST });

      const response = await ApiList.updateLessonSetting(request, uuid);
      // console.log("response createCourse", response);
      if (response) {
        dispatch({
          type: types.UPDATE_LESSON_SETTING_SUCCESS,
          payload: response,
        });
        if (onStepChange) onStepChange();
        if (setIsStep2Complete) setIsStep2Complete(true);
        toast.success(isLessonEdit && "Lesson Setting Updated Successfully");
      } else {
        dispatch({
          type: types.UPDATE_LESSON_SETTING_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_LESSON_SETTING_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const lessonAssignToCourseAction = ({
  request,
  uuid,
  navigate,
  isLessonEdit,
}) => {
  // console.log("update course setting", request);
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.LESSON_ASSIGN_TO_COURSE_REQUEST });

      const response = await ApiList.lessonAssignToCourse(uuid, request);
      // console.log("response createCourse", response);
      if (response) {
        dispatch({
          type: types.LESSON_ASSIGN_TO_COURSE_SUCCESS,
          payload: response,
        });

        navigate("/lesson");
        toast.success(
          isLessonEdit ? "Updated Successfully" : "Lesson Created Successfully"
        );
      } else {
        dispatch({
          type: types.LESSON_ASSIGN_TO_COURSE_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.LESSON_ASSIGN_TO_COURSE_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const lessonEditAction = ({
  request,
  uuid,
  onStepChange,
  setIsStep1Complete,
}) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.LESSON_EDIT_REQUEST });

      const response = await ApiList.updateLesson(request, uuid);

      if (response) {
        dispatch({
          type: types.LESSON_EDIT_SUCCESS,
          payload: response,
        });

        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);

        toast.success("Lesson Updated Successfully");
      } else {
        dispatch({
          type: types.LESSON_EDIT_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.LESSON_EDIT_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const lessonDetailsAction = (uuid, flag, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.LESSON_DETAILS_REQUEST });

      const response = await ApiList.lessonDetails(uuid);
      if (response.status == 200) {
        dispatch({
          type: types.LESSON_DETAILS_SUCCESS,
          payload: response,
        });
        if (flag == 1) {
          navigate("/lesson/live-class", {
            state: {
              uuid: uuid,
            },
          });
        }
      } else {
        dispatch({
          type: types.LESSON_DETAILS_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.LESSON_EDIT_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createLiveClassAction = (
  request,
  createType,
  lessonUuid,
  navigate
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_LIVE_CLASS_REQUEST });

      const response = await ApiList.createLiveClass(request);
      if (response) {
        dispatch({ type: types.CREATE_LIVE_CLASS_SUCCESS, payload: response });
        toast.success("Live Class Created Successfully");
        if (createType == "fromLiveClass") {
          dispatch(lessonDetailsAction(lessonUuid, 1, navigate));
        }
      } else {
        dispatch({ type: types.CREATE_LESSON_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response), 100);
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      dispatch({ type: types.CREATE_LIVE_CLASS_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.success("Something Went Wrong");
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getLiveClassAction = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.LIVE_CLASS_LIST_REQUEST });

      const response = await ApiList.getLiveClass(page);
      // console.log('response',response.data.data)
      if (response) {
        dispatch({
          type: types.LIVE_CLASS_LIST_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({ type: types.CREATE_LESSON_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.LIVE_CLASS_LIST_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const updateLiveClassAction = (courseId, request) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_LIVE_CLASS_REQUEST });

      const response = await ApiList.updateLiveClass(courseId, request);
      if (response) {
        dispatch({ type: types.UPDATE_LIVE_CLASS_SUCCESS, payload: response });
      } else {
        dispatch({ type: types.UPDATE_LIVE_CLASS_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_LIVE_CLASS_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const deleteLiveClassAction = (
  id,
  setModalShow,
  lessonUuid,
  deleteType,
  navigate
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.DELETE_LIVE_CLASS_REQUEST });
      const response = await ApiList.deleteLiveClass(id);
      if (response) {
        dispatch({ type: types.DELETE_LIVE_CLASS_SUCCESS, payload: response });
        toast.success(response?.message);
        setModalShow(false);
        // navigate("/lesson")
        let flag = 1;
        if (deleteType == "liveClass") {
          dispatch(lessonDetailsAction(lessonUuid, flag, navigate));
        } else if (deleteType == "allLiveClass") {
          // console.log('jher')
          // navigate("/live-class")
          window.location.reload();
        }
      } else {
        dispatch({ type: types.DELETE_LIVE_CLASS_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.DELETE_LIVE_CLASS_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getLiveClassTokenAction = (request, navigate) => {
  // console.log('requests', request,)
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.GET_LIVE_CLASS_TOKEN_REQUEST });

      const response = await ApiList.getLiveClassToken(request);

      // let zoomToken = response.data.data.signature;
      const url = "/join-meeting";
      if (response.status == 200) {
        dispatch({
          type: types.GET_LIVE_CLASS_TOKEN_SUCCESS,
          payload: response,
        });
        // navigate("/join-meeting", {
        //   state: { data: request.sessionName, zoomToken },
        // });

        window.open(
          `${url}?token=${encodeURIComponent(
            response.data.data.signature
          )}&sessionName=${encodeURIComponent(request.sessionName)}`,
          "_blank"
        );
      } else {
        dispatch({ type: types.GET_LIVE_CLASS_TOKEN_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.GET_LIVE_CLASS_TOKEN_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const editLiveClassAction = (
  request,
  id,
  setModalShow,
  uuid,
  editType,
  navigate
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.EDIT_LIVE_CLASS_REQUEST });
      const response = await ApiList.editLiveClass(request, id);
      if (response.status == 200) {
        dispatch({ type: types.EDIT_LIVE_CLASS_SUCCESS, payload: response });
        toast.success("Live Class Edited Successfully");
        setModalShow(false);
        let flag = 1;
        if (editType == "liveClass") {
          dispatch(lessonDetailsAction(uuid, flag, navigate));
        } else if (editType == "allLiveClass") {
          // console.log('jher')
          // navigate("/live-class")
          window.location.reload();
        }
      } else {
        dispatch({ type: types.EDIT_LIVE_CLASS_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.EDIT_LIVE_CLASS_ERROR, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
