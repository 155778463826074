import toast from "react-hot-toast";
import * as types from "./actionTypes";
import { apiLoadingStart, apiLoadingStop } from "../global";
import ApiList from "../../webservice/ApiList";

export const resetStore = () => ({
  type: types.RESET_STORE_USER,
});

export const loginAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.LOGIN_USER_REQUEST });

    try {
      const response = await ApiList.userAuthLogin(request);

      if (response?.token) {
        const accessToken = `${response.token}`;
        localStorage.setItem("accessToken", accessToken);
        dispatch({ type: types.LOGIN_USER_SUCCESS, payload: response });
        window.location.reload();
        dispatch(getProfileAction());
      } else {
        // Handle error directly inside the function
        dispatch({
          type: types.LOGIN_USER_ERROR,
          payload: "There are some problems",
        });
        toast.error(response.error.statusText);
      }
    } catch (error) {
      dispatch({
        type: types.LOGIN_USER_ERROR,
        payload: error?.error || "An error occurred during login",
      });
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const getProfileAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GETPROFILE_REQUEST });

    try {
      const response = await ApiList.getProfileData();
      //console.log("get profile data", response);

      if (response) {
        //console.log("Profile fetch successful",response);
        //navigate('/dashboard')
        // window.location.reload();
        dispatch({
          type: types.GETPROFILE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: types.GETPROFILE_ERROR, payload: response.status });
      }
    } catch (error) {
      dispatch({
        type: types.GETPROFILE_ERROR,
        payload: error?.error || "An error occurred while fetching the profile",
      });
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

// export const sendOTPAction = (request, navigation, phone) => {
// 	// console.log('request', phone)
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.SEND_OTP_REQUEST });
// 		try {
// 			return ApiList.sendOtp(request).then(response => {
// 				// console.log('response send otp',response)
// 				if (response.user_id) {
// 					//console.log('dsdfdf')
// 					// let access_token =response.token_type+'  '+response.access_token;
// 					// //console.log('acc',access_token)
// 					// localStorage.setItem('accessToken',access_token)
// 					// let request= {}
// 					// request.user_id = response.user_id
// 					// dispatch(getIsAlreadyLoginAction(request))
// 					let request = {}
// 					request.user_id = response.user_id
// 					// console.log('request', request)
// 					dispatch(sessionMatchAction(request, 1)) // ignore the device ID

// 					// dispatch(getIsAlreadyLoginAction(request))
// 					dispatch({ type: types.SEND_OTP_SUCCESS, payload: response.user_id });
// 					navigation.navigate('VerifyScreen', { phone: phone })
// 					//dispatch({ type: types.LOGIN_USER_SUCCESS, payload: false });
// 					dispatch(apiLoadingStop());
// 				} else if (response.status === 404) {
// 					dispatch({ type: types.SEND_OTP_SUCCESS, payload: "" });
// 					Toast.show({ type: 'success', text2: response.message });
// 					navigation.navigate('SignUp', { phone: phone })
// 				}

// 				else {
// 					dispatch({ type: types.SEND_OTP_ERROR, payload: "There are some problem" });
// 					setTimeout(() => {Toast.show({ type: 'error', text2: 'There are some problem' })}, 100);

// 				}
// 				dispatch(apiLoadingStop());
// 				dispatch({ type: types.SEND_OTP_ERROR, });
// 			}).catch((error) => {
// 				//console.log('error',error)
// 				dispatch({ type: types.SEND_OTP_ERROR, payload: error.error });
// 				//dispatch({ type: types.LOGIN_USER_ERROR, payload: false });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: 'Enter valid phone number' })}, 100);

// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };
// export const checkPhoneNumberAction = (request, navigation, phone, request2) => {
// 	// console.log('request2', request2);
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.CHECK_NUMBER_REQUEST });
// 		try {
// 			return ApiList.sendOtp(request).then(response => {
// 				// console.log('response send otp resgiter',response)
// 				if (response.status === 200) {

// 					// let access_token =response.token_type+'  '+response.access_token;
// 					//console.log('acc',access_token)
// 					// localStorage.setItem('accessToken',access_token)
// 					//dispatch(signUpAction(request2,navigation,phone))
// 					navigation.navigate("VerifyScreen", { phone: phone, request2: request2 })
// 					dispatch({ type: types.CHECK_NUMBER_SUCCESS, payload: response });

// 					//dispatch({ type: types.LOGIN_USER_SUCCESS, payload: false });
// 					dispatch(apiLoadingStop());
// 				} else if (response.status === 404) {
// 					Toast.show({ type: 'error', text2: response.message })

// 					// navigation.navigate('SignUp')
// 				}

// 				else {
// 					dispatch({ type: types.CHECK_NUMBER_ERROR, payload: "There are some problem" });
// 					setTimeout(() => {
// 						// Toast.show("There are some problem");
// 						Toast.show({ type: 'error', text2: 'There are some problem' })

// 					}, 100);
// 				}
// 				dispatch(apiLoadingStop());
// 				dispatch({ type: types.CHECK_NUMBER_ERROR, });
// 			}).catch((error) => {
// 				//console.log('error',error)
// 				dispatch({ type: types.CHECK_NUMBER_ERROR, payload: error.error });
// 				//dispatch({ type: types.LOGIN_USER_ERROR, payload: false });
// 				setTimeout(() => {
// 					// Toast.show("Enter valid phone number");
// 					Toast.show({ type: 'error', text2: 'Enter valid phone number' })

// 				 }, 100);
// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };
// export const verifyOTPAction = (request, navigation) => {
// 	console.log('request', request)
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.VERIFY_OTP_REQUEST });
// 		try {
// 			return ApiList.verifyOtp(request).then(response => {
// 				 console.log('response===> verify', response)
// 				if (response.access_token) {
// 					//console.log('dsdfdf')
// 					let access_token = response.token_type + '  ' + response.access_token;
// 					let access_session = response.session_id

// 					//console.log('acc',access_token)
// 					localStorage.setItem('accessToken', access_token)
// 					// if(access_session !== null){
// 					// 	//localStorage.setItem('accessSession',access_session)
// 					// }

// 					// dispatch(clearCartAction())
// 					dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: response });
// 					dispatch(getIsAlreadyLoginAction(request.user_id));
// 					setTimeout(() => { dispatch(getProfileAction(navigation)) }, 100);

// 					//dispatch({ type: types.LOGIN_USER_SUCCESS, payload: false });
// 					dispatch(apiLoadingStop());
// 				} else if (response.status === "success" && response.login_from_web === 1) {
// 					//Alert.alert("You are already login in web")
// 				//	AlertIOS.alert("You are already login in another device", )

// 					let newData = {
// 						...request, // Spread the existing object properties
// 						force_logout: 1, // Add the new parameter "foreLog" with a value of 1
// 					};
// 					// console.log('request', newData)

// 					dispatch(verifyOTPAction(newData, navigation))
// 					//dispatch(getIsAlreadyLoginAction())
// 					dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: response });
// 				}
// 				else {
// 					dispatch({ type: types.VERIFY_OTP_ERROR, payload: "There are some problem" });
// 					setTimeout(() => {Toast.show({ type: 'error', text2: 'Invalid Otp' })}, 100);

// 				}
// 				dispatch(apiLoadingStop());
// 				dispatch({ type: types.VERIFY_OTP_ERROR, });
// 			}).catch((error) => {
// 				console.log('error',error)
// 				dispatch({ type: types.VERIFY_OTP_ERROR, payload: error.error });
// 				//dispatch({ type: types.LOGIN_USER_ERROR, payload: false });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: 'Enter valid otp' })}, 100);

// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };
// export const registerVerifyOTPAction = (request, navigation, apiRequest) => {
// 	// console.log('request', request)
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.VERIFY_OTP_REQUEST });
// 		try {
// 			return ApiList.registerVerifyOtp(request).then(response => {
// 				// console.log('request2', request)
// 				// console.log('apiRequest', apiRequest)
// 				//  console.log('response===> register',response)

// 				if (response.status === 200) {
// 					dispatch(signUpAction(apiRequest, navigation))
// 				}

// 				// else if (response.access_token) {
// 				// 	//console.log('dsdfdf')
// 				// 	let access_token =response.token_type+'  '+response.access_token;
// 				// 	//console.log('acc',access_token)
// 				// 	localStorage.setItem('accessToken',access_token)
// 				// 	dispatch(clearCartAction())
// 				// 	setTimeout(() => { dispatch(getProfileAction(navigation)) }, 100);
// 				// 	dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: response });
// 				// 	navigation.navigate('Home')
// 				// 	//dispatch({ type: types.LOGIN_USER_SUCCESS, payload: false });
// 				// 	dispatch(apiLoadingStop());
// 				// }
// 				else {
// 					dispatch({ type: types.VERIFY_OTP_ERROR, payload: "There are some problem" });
// 					setTimeout(() => {Toast.show({ type: 'error', text2: 'There are some problem' })}, 100);

// 				}
// 				dispatch(apiLoadingStop());
// 				dispatch({ type: types.VERIFY_OTP_ERROR, });
// 			}).catch((error) => {
// 				//console.log('error',error)
// 				dispatch({ type: types.VERIFY_OTP_ERROR, payload: error.error });
// 				//dispatch({ type: types.LOGIN_USER_ERROR, payload: false });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: 'Enter valid otp' })}, 100);

// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };
// export const logoutAction = (navigation) => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		return ApiList.userAuthLogout().then(response => {
// 				// console.log('log out response', response)
// 			if (response.message == "Successfully logged out") {
// 				dispatch(resetStore());
// 				localStorage.setItem('accessToken', '');
// 				localStorage.setItem('accessToken', '');
// 				// localStorage.setItem('accessSession','')
// 				// localStorage.removeItem('QuestionData');
// 				// localStorage.removeItem('multiselect');
// 				localStorage.removeItem('Answers');
// 				localStorage.removeItem('markandReviewQuestion')
// 				localStorage.removeItem('createResult');
// 				// localStorage.removeItem('setTheTestStartedTime');
// 				// localStorage.removeItem('collectPausedTime');
// 				// localStorage.removeItem('StoreLastExamTime');

// 				navigation.navigate("OtpScreen")
// 				dispatch({ type: types.LOGOUT_USER_SUCCESS, payload: response.result });

// 			} else {
// 				dispatch({ type: types.LOGIN_USER_ERROR, payload: response.message });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {

// 			dispatch({ type: types.LOGIN_USER_ERROR, payload: error.error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };
// export const getIsAlreadyLoginAction = (request) => {
// 	//const navigation = useNavigation()

// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.CHECK_LOGIN_REQUEST });

// 		return ApiList.getIsAlreadyLogin(request).then(response => {
// 			console.log('check logineeeeeeeeeeeee', response)
// 			if (response.status === 200) {
// 				dispatch({ type: types.CHECK_LOGIN_SUCCESS, payload: response.data });
// 				// if(response.data === 1){
// 				// 	dispatch(forcelogoutAction(navigation));
// 				// }
// 				//dispatch(forcelogoutAction())
// 			}else{
// 				dispatch({ type: types.CHECK_LOGIN_ERROR, payload: response.message });
// 				//setTimeout(() => { AlertIOS.alert(response.message, ); }, 100);

// 			}
// 			// if (response.message === 200) {
// 			// 	// localStorage.setItem('accessToken', '');
// 			// 	// navigation.navigate("OtpScreen")
// 			// 	dispatch({ type: types.CHECK_LOGIN_SUCCESS, payload: response.data });

// 			// } else {
// 			// 	dispatch({ type: types.CHECK_LOGIN_ERROR, payload: response.message });
// 			// 	setTimeout(() => { Toast.show(response.message); }, 100);
// 			// }
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {

// 			dispatch({ type: types.CHECK_LOGIN_ERROR, payload: error.error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);

// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const forcelogoutAction = (navigation) => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		return ApiList.pushforceLogout().then(response => {

// 			 //console.log('log out response', response)
// 			if (response.status == 200) {
// 				dispatch(resetStore());
// 				localStorage.setItem('accessToken', '');
// 				// localStorage.setItem('accessSession','')
// 				// localStorage.removeItem('QuestionData');
// 				// localStorage.removeItem('multiselect');
// 				localStorage.removeItem('Answers');
// 				localStorage.removeItem('markandReviewQuestion')
// 				localStorage.removeItem('createResult');
// 				// localStorage.removeItem('setTheTestStartedTime');
// 				// localStorage.removeItem('collectPausedTime');
// 				// localStorage.removeItem('StoreLastExamTime');

// 				navigation.navigate("OtpScreen")
// 				dispatch({ type: types.LOGOUT_USER_SUCCESS, payload: response });
// 				Toast.show({ type: 'success', text2: 'Successfully logged out' });

// 			} else {
// 				dispatch({ type: types.LOGIN_USER_ERROR, payload: response });
// 				//setTimeout(() => { Toast.show(response); }, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {

// 			dispatch({ type: types.LOGIN_USER_ERROR, payload: error.error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);

// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const getProfileAction2 = () => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());

// 		return ApiList.getProfileData().then(response => {
// 			//  console.log('get profile data getProfileAction2', response)
// 			if (response.status == 'success') {
// 				//setTimeout(() => { navigation.navigate('Home') }, 5);
// 				dispatch({ type: types.GETPROFILE_SUCCESS, payload: response.result,payload2:response.purchasedCourse, payload3: response.globalDoubtnetAttempts });

// 			} else {
// 				dispatch({ type: types.GETPROFILE_ERROR, payload: response.status });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.status })}, 100);

// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {

// 			dispatch({ type: types.GETPROFILE_ERROR, payload: error.error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);
// 			dispatch(apiLoadingStop());
// 		});

// 	};
// };

// export const signUpAction = (request, navigation) => {
// 	// console.log('check requestsssssss', request)
// 	return (dispatch) => {
// 		// console.log('here1')
// 		dispatch(apiLoadingStart());
// 		//dispatch({ type: types.SINGUP_USER_REQUEST });
// 		try {
// 			// console.log('here2',request)
// 			return ApiList.signup(request).then(response => {
// 				//console.log('check request for signup', request)
// 				//  console.log('response for signup', response)
// 				if (response === null) {
// 					dispatch({ type: types.SINGUP_USER_ERROR, payload: "There are some problem" });
// 					Toast.show({ type: 'error', text2: 'There are some problem' })

// 				}
// 				else if (response.access_token) {
// 					//console.log('dsdfdf')
// 					let access_token = response.token_type + '  ' + response.access_token;
// 					let access_session = response.session_id
// 					//console.log('acc',access_token)
// 					localStorage.setItem('accessToken', access_token)
// 					if(access_session !== null){
// 						// localStorage.setItem('accessSession',access_session)
// 					}
// 					//dispatch(clearCartAction())
// 					setTimeout(() => { dispatch(getProfileAction(navigation)) }, 100);
// 					dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: response });
// 					//navigation.navigate('Home')
// 					//dispatch({ type: types.LOGIN_USER_SUCCESS, payload: false });
// 					dispatch(apiLoadingStop());
// 				}

// 				else if (response.status == "success") {
// 					//dispatch({ type: types.SINGUP_USER_SUCCESS, payload: response.result });
// 					dispatch({ type: types.SINGUP_USER_SUCCESS, payload: response.status });
// 					Toast.show({ type: 'success', text2: response.message });

// 					//dispatch(loginAction(request, navigation))
// 					//navigation.navigate('VerifyScreen', {phone:phone})
// 					//navigation.navigate('OtpScreen')
// 				} else {
// 					dispatch({ type: types.SINGUP_USER_ERROR, payload: response.message });
// 					setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);

// 				}
// 				dispatch(apiLoadingStop());
// 			}).catch((error) => {
// 				// console.log('err1', error)
// 				dispatch({ type: types.SINGUP_USER_ERROR, payload: error });
// 				Toast.show({ type: 'error', text2: error });
// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			// console.log('eerrr', err)
// 		}
// 	};
// };

// export const sessionMatchAction = (request, flagcheck) => {
// 	// console.log("session request", request)
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.SESSION_USER_REQUEST });
// 		return ApiList.sessionMatch(request).then(response => {
// 			// console.log('sessionMatch',response)
// 			if (response.status == 200) {
// 				if(flagcheck == 1){
// 					// console.log('hereeeeeeeeeeeeeeeeeeeeeeeeeee')
// 					//  one for  ignore the sessionID(device ID)
// 					dispatch({ type: types.SESSION_USER_SUCCESS, payload: response });

// 				}else{
// 					// console.log('thereeeeeeeeeeeeeeeeeeeeeeee')
// 					//  zero for ignore the sessionCheck() and device id save
// 					dispatch({ type: types.SESSION_USER_SUCCESS2, payload: response });
// 				}

// 			} else {
// 				dispatch({ type: types.SESSION_USER_ERROR, payload: response.message });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			// console.log('err',error)
// 			dispatch({ type: types.SESSION_USER_ERROR, payload: error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);

// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };
// export const forgotPasswordAction = (request, navigation) => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
// 		return ApiList.forgotPassword(request).then(response => {
// 			//console.log('response for forgot password', response)
// 			if (response.status == 200) {
// 				dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: response.result });
// 			} else {
// 				dispatch({ type: types.FORGOT_PASSWORD_ERROR, payload: response.message });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			dispatch({ type: types.FORGOT_PASSWORD_ERROR, payload: error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);
// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const getContactUsAction = (request, navigation) => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.CONTACTUS_REQUEST });
// 		return ApiList.getContactUs(request).then(response => {
// 			//console.log('getContactUs response',response)
// 			if (response.status == "success") {
// 				dispatch({ type: types.CONTACTUS_SUCCESS, payload: response.result });
// 				// setTimeout(() => { AlertHelper.success(response.message); }, 50);
// 				setTimeout(() => { navigation.navigate('Home') }, 5);
// 				Toast.show({ type: 'success', text2: 'Message send successfully' });
// 			} else {
// 				dispatch({ type: types.CONTACTUS_ERROR, payload: response.message });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);

// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			dispatch({ type: types.CONTACTUS_ERROR, payload: error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);

// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const changeUserCurrentLanguage = (params) => {
// 	return (dispatch) => {
// 		return ApiList.updateData(params).then(response => {
// 			if (response.status == 200) {
// 				dispatch({ type: types.UPDATE_USER_LANGUAGE_SUCCESS, payload: response.result });
// 			}
// 		});
// 	};
// };

// export const changeUserCurrentLanguage = (params) => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		return ApiList.updateData(params).then(response => {
// 			if (response.status == 200) {

// 				dispatch({ type: types.UPDATE_USER_LANGUAGE_SUCCESS, payload: response.result });

// 				dispatch(apiLoadingStop());
// 			} else {
// 				dispatch({ type: types.UPDATE_USER_LANGUAGE_ERROR, payload: response.message });
// 				setTimeout(() => { Toast.show(response.message); }, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			dispatch({ type: types.UPDATE_USER_LANGUAGE_ERROR, payload: error.error });
// 			dispatch(apiLoadingStop());
// 			setTimeout(() => { Toast.show(error.error); }, 100);

// 		});
// 	};
// };
// export const updateProfileAction = (request, navigation) => {
// 	//console.log('params', request)
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.UPDATEPROFILE_REQUEST });
// 		try {
// 			return ApiList.updateData(request).then(response => {
// 				//  console.log('update profile',response)
// 				if (response.status == "success") {
// 					 navigation.navigate('Home')

// 					dispatch({ type: types.UPDATEPROFILE_SUCCESS, payload: response });

// 					dispatch(apiLoadingStop());
// 				} else {
// 					dispatch({ type: types.UPDATEPROFILE_ERROR, payload: response.message });
// 					setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 				}
// 				dispatch(apiLoadingStop());
// 			}).catch((error) => {
// 				dispatch({ type: types.UPDATEPROFILE_ERROR, payload: error.error });
// 				dispatch(apiLoadingStop());
// 				setTimeout(() => {Toast.show({ type: 'error', text2: error.error})}, 100);

// 			});
// 		} catch (err) {
// 			//console.log('erreeeeeeeeeeeee', err)
// 		}
// 	};
// };

// export const getMyPurchaseAction = () => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.MYPURCHASE_REQUEST });
// 		return ApiList.getMyPurchase().then(response => {
// 			//console.log('my purchase response', response)
// 			if (response.status == "success") {
// 				dispatch({ type: types.MYPURCHASE_SUCCESS, payload: response.result });
// 			} else {
// 				dispatch({ type: types.MYPURCHASE_ERROR, payload: response.status });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.status})}, 100);

// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			dispatch({ type: types.MYPURCHASE_ERROR, payload: error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);
// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const myCertificateAction = () => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.MYCERTIFICATE_REQUEST });
// 		return.apiMyCertificate().then(response => {
// 			//console.log('my certificate resposne', response)
// 			if (response.status == "success") {
// 				dispatch({ type: types.MYCERTIFICATE_SUCCESS, payload: response.result });
// 			} else {
// 				dispatch({ type: types.MYCERTIFICATE_ERROR, payload: response.message });
// 				setTimeout(() => {Toast.show({ type: 'error', text2: response.message })}, 100);
// 			}
// 			dispatch(apiLoadingStop());
// 		}).catch((error) => {
// 			dispatch({ type: types.MYCERTIFICATE_ERROR, payload: error });
// 			setTimeout(() => {Toast.show({ type: 'error', text2: error })}, 100);
// 			dispatch(apiLoadingStop());
// 		});
// 	};
// };

// export const getClassesAction = () => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.CLASSESDATA_REQUEST });
// 		try {
// 			return ApiList.getClassesData().then(response => {
// 				//  console.log('getClasses data response', response)
// 				if (response.status === 'success') {
// 					dispatch({ type: types.CLASSESDATA_SUCCESS, payload: response.data });
// 					dispatch(apiLoadingStop());
// 				}
// 				else {
// 					dispatch({ type: types.CLASSESDATA_ERROR, payload: response.status});
// 					dispatch(apiLoadingStop());
// 				}
// 				// dispatch(apiLoadingStop());
// 				// dispatch({ type: types.VERIFY_OTP_ERROR });
// 			}).catch((error) => {
// 				// console.log('error',error)
// 				dispatch({ type: types.CLASSESDATA_ERROR, payload: error.error });
// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };

// export const  getStreamAction= () => {
// 	return (dispatch) => {
// 		dispatch(apiLoadingStart());
// 		dispatch({ type: types.STREAMSDATA_REQUEST });
// 		try {
// 			return ApiList.getStreamsData().then(response => {
// 				//   console.log('streamdata response', response)
// 				if (response.status === "success") {
// 					dispatch({ type: types.STREAMSDATA_SUCCESS, payload: response.data });
// 					dispatch(apiLoadingStop());
// 				}
// 				else {
// 					dispatch({ type: types.STREAMSDATA_ERROR, payload: response.message });
// 					dispatch(apiLoadingStop());
// 				}
// 				// dispatch(apiLoadingStop());
// 				// dispatch({ type: types.STREAMSDATA_ERROR});
// 			}).catch((error) => {
// 				//console.log('error',error)
// 				dispatch({ type: types.STREAMSDATA_ERROR, payload: error.error });
// 				dispatch(apiLoadingStop());
// 			});
// 		} catch (err) {
// 			//console.log('login err', err)
// 		}
// 	};
// };
